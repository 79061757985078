<template>
    <modal-lateral ref="verInstrucciones" titulo="Instrucciones de entrega">
        <div v-if="pedido.instrucciones !== null" class="">
            <div class="row m-3">
                <div class="col f-14">
                    {{ pedido.instrucciones }}
                </div>
            </div>
        </div>
        <div v-if="pedido.instrucciones === null" class="row justify-center mt-3">
            Sin instrucciones de entrega
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return{
            pedido:{}
        }
    },
    methods: {
        toggle(pedido){
            this.pedido = pedido
            this.$refs.verInstrucciones.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
